import style from './index.module.scss';
import { Button, IntrinsicProps, createComponent } from 'react-commons';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';

interface LoginButtonProps extends IntrinsicProps {
  loginOnClick?: boolean
}

export default createComponent<LoginButtonProps>('LoginButton', { style }, function LoginButton ({ className }, props) {
  const { login } = useAuthMethods();

  return (
    <Button 
      secondary 
      medium 
      onClick={() => props.loginOnClick && login()} 
      className={className}
    >
      <span>Login</span>
      <span>Signup</span>
    </Button>
  );
});
