import { If, IntrinsicProps, createComponent, useModal } from 'react-commons';
import LoginForm from '@/components/forms/LoginForm';
import RegisterForm from '@/components/forms/RegisterForm';
import RequestResetForm from '@/components/forms/RequestResetForm';
import { popoverEvents } from '@/components/Popover';
import { captureEvent } from '@/lib/util/captureEvent';
import { useEffect, useRef } from 'react';

interface MobileUpsellBannerProps extends IntrinsicProps {
  formVisible: 'login' | 'register' | 'resetPassword'
  setFormVisible: (formVisible: 'login' | 'register' | 'resetPassword') => void
}

export default createComponent<MobileUpsellBannerProps>('MobileUpsellBanner', {}, function MobileUpsellBanner ({},props) {
  const { closeModal } = useModal();

  const closePopover = () => {
    popoverEvents.trigger('closeAll');
    closeModal();
  };

  const lastFormVisibleRef = useRef(props.formVisible);
  useEffect(() => {
    if (props.formVisible === 'login' && lastFormVisibleRef.current === 'register') {
      captureEvent('login_start', {
        tag_name: 'login_header_start',
      });
      lastFormVisibleRef.current = 'login';
    } else if (props.formVisible === 'register') {
      captureEvent('signup_start', {
        tag_name: 'register_header_start',
      });
      lastFormVisibleRef.current = 'register';
    }
  }, [ props.formVisible ]);
  
  return (
    <>
      {
        If(props.formVisible === 'login', () => (
          <LoginForm
            uaTagName='login_header'
            onSuccess={() => closePopover()}
            onRegisterClicked={() => props.setFormVisible('register')}
            onResetPasswordClicked={() => props.setFormVisible('resetPassword')}
          />
        ))
          .ElseIf(props.formVisible === 'register', () => (
            <RegisterForm
              uaTagName='register_header'
              onSuccess={() => closePopover()}
              onLoginClicked={() => props.setFormVisible('login')}
              onResetPasswordClicked={() => props.setFormVisible('resetPassword')}
            />
          ))
          .ElseIf(props.formVisible === 'resetPassword', () => (
            <RequestResetForm
              onSuccess={() => closePopover()}
              onLoginClicked={() => props.setFormVisible('login')}
              onRegisterClicked={() => props.setFormVisible('register')}
            />
          ))
          .EndIf()
      }
    </>
  );
});
