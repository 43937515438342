import axios from '@/lib/drupal/api/axiosInstance';
import GamesModel, { DrupalGameData, ExtendedGameData, FeaturedGameData, GameData, mockGameSchema } from '@/lib/drupal/models/Games';
import { mockGet } from '@/lib/util/mockRequest';
import { pagingQueryString } from '@/lib/util/pagingQueryString';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';

export default class HomeModel {
  static async getNewOnlineGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/neweset-online-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get New Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getBestGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/best-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Best Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getDownloadGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/download-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getWordGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/word-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Word Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getStrategyGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/strategy-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Strategy Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getPartnerGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/exclusive-shockwave-partners?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Partner Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getJigsawGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/jigsaw-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get Jigsaw Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getNewDownloadGames (limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/home/block/new-download-games?' + pagingQueryString(limit, offset));
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get New Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getFeaturedCarouselGames (): Promise<FeaturedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/sw/api/home/block/featured-slider');
      // response = await mockGet([ mockGameSchema ], 4, 0);
    } catch (error) {
      logErrorEvent('Get Featured Carousel Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transformFeatured(game));
  }

  static async getMobileGames (): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/mobile-games');
    } catch (error) {
      logErrorEvent('Get Mobile Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformMobile(game));
  }
}
