import { appActions, useAppStore } from '@/lib/stores/app';
import { faBars, faClock, faGear, faHeart, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Block, Button, ButtonLink, Container, EventListener, Flex, For, IconFA, If,  Loader,  Menu, MenuLabel, MenuList, Modal, ModalCard, RouterLink, Slot, logErrorEvent, useModal, useResizeX } from 'react-commons';
import { createComponent } from 'react-commons';
import PerDevice from '../PerDevice';
import animate from 'animejs';

import { useRouter } from 'next/router';
import GameTileImg from '../GameTileImg';
import { GenreIconButtons, GenreIconButton } from './GenreIconButton';
import HeaderLinkCaret from './HeaderLinkCaret';
import UserStatus from './UserStatus';
import SwSearch, { SwSearchButton, swSearchEvents } from './SwSearch';
import { popoverEvents } from '../Popover';
import { authActionSets, useAuthStore } from '@/lib/drupal/stores/auth';
import LoginButton from '@/components/LoginButton';
import AuthForms from '@/components/AuthForms';
import MobileUserStatus from '@/components/AppHeader/MobileUserStatus';
import HomeModel from '@/lib/drupal/models/Home';
import { ExtendedGameData, GameData, mobileHref } from '@/lib/drupal/models/Games';
import { useRecentlyPlayed } from '@/lib/stores/recentlyPlayed';

import style from './index.module.scss';
import SwGameTile from '@/components/SwGameTile';
import SwPaginator from '@/components/SwPaginator';
import UsersModel from '@/lib/drupal/models/Users';

export const appHeaderEvents = new EventListener<{
  showLogin: () => void
}>();

function getCurrentMenuEl (el: HTMLDivElement) {
  return el.getElementsByTagName('div')[ 0 ];
}

export default createComponent('AppHeader', { style }, function AppHeader ({ className }) {
  const [ appState, appDispatch ] = useAppStore();
  const [ authState, _authDispatch, authExecute ] = useAuthStore();
  const [ recentlyPlayed ] = useRecentlyPlayed();
  const { openModal } = useModal();
  const router = useRouter();

  const menuRef = useRef<HTMLDivElement | null>(null);
  const isRefreshingUserRef = useRef(false);
  const [ currentMenu, setCurrentMenu ] = useState<'onlineGames' | 'downloadGames' | 'myShockwave' | null>(null);
  const [ menuIsOpen, setMenuIsOpen ] = useState<boolean>(false);
  const [ searchIsOpen, setSearchIsOpen ] = useState<boolean>(false);
  const [ mobileFormVisible, setMobileFormVisible ] = useState<'login' | 'register' | 'resetPassword'>('login');

  const desktopMenuRef = useRef<HTMLDivElement | null>(null);
  const desktopLinksRef = useRef<HTMLDivElement | null>(null);

  const toggleMobileMenu = () => {
    setMenuIsOpen((current) => !current);
  };

  const toggleDesktopMenu = async (nextMenu: 'onlineGames' | 'downloadGames' | 'myShockwave', target: HTMLElement) => {
    if (
      nextMenu === 'myShockwave' && 
      (!menuIsOpen || currentMenu !== 'myShockwave') && 
      !isRefreshingUserRef.current
    ) {
      (async () => {
        isRefreshingUserRef.current = true;
        await authExecute(authActionSets.refreshUser());
        isRefreshingUserRef.current = false;
      })();
    }

    if (menuIsOpen && nextMenu === currentMenu) {
      setMenuIsOpen(false);
    } else {
      if (menuIsOpen) {
        document
          .querySelectorAll('.AppHeader__DesktopHeaderLink')
          .forEach(el => el.classList.remove('AppHeader__DesktopHeaderLink--active'));

        target
          .closest('.AppHeader__DesktopHeaderLink')
          .classList.add('AppHeader__DesktopHeaderLink--active');

        await animate({
          targets: getCurrentMenuEl(desktopMenuRef.current),
          opacity: [ 1, 0 ],
          duration: 250,
          easing: 'easeInOutCubic',
        }).finished;
      }

      setMenuIsOpen(true);
      setCurrentMenu(nextMenu);
    }
  };

  // Handle menu openining, and closing when you click outside
  useEffect(() => {
    if (menuIsOpen && !appState.mobileGames.length) {
      (async () => {
        const mobileGames = await HomeModel.getMobileGames();
        appDispatch(appActions.setMobileGames(mobileGames));
      })();
    }

    menuRef.current.classList.toggle('AppHeader__Menu--open', !!menuIsOpen);

    const onClickOutsideHandler = (event) => {
      if (!event.target.closest || !event.target.closest('.AppHeader')) {
        setMenuIsOpen(false);
      }
    };
    document.addEventListener('mousedown', onClickOutsideHandler);
    window.addEventListener('blur', onClickOutsideHandler);

    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
      window.removeEventListener('blur', onClickOutsideHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ menuIsOpen ]);

  // Transition desktop menu in
  useEffect(() => {
    const el = getCurrentMenuEl(desktopMenuRef.current);
    
    animate({
      targets: el,
      opacity: [ 0, 1 ],
      duration: 250,
      easing: 'easeInOutCubic',
    }).finished;
  }, [ currentMenu ]);

  // If page navigation occurs, close the menu
  useEffect(() => {
    const closeMenu = () => setMenuIsOpen(false);
    router.events.on('routeChangeStart', closeMenu);

    return () => router.events.off('routeChangeStart', closeMenu);
  }, [ router.events ]);

  // Toggle search visible state on the element
  useEffect(() => {
    if (!desktopLinksRef.current) return;
    desktopLinksRef.current.classList.toggle('AppHeader__DesktopHeader--searchOpen', searchIsOpen);
    if (searchIsOpen) swSearchEvents.trigger('focus');
  }, [ searchIsOpen ]);

  useResizeX(() => {
    if (searchIsOpen) setSearchIsOpen(false);
  });

  // Auth event listeners
  useEffect(() => {
    const handleShowLogin = () => {
      if (!!window.matchMedia('(max-device-width: 750px)').matches) {
        setMobileFormVisible('login');
        openModal('mobileLogin');
        return;
      }
      
      window.scrollTo({ top: 0, behavior: 'smooth' });
      popoverEvents.trigger('open', 'authPopover');
    };
    appHeaderEvents.on('showLogin', handleShowLogin);

    return () => {
      appHeaderEvents.off('showLogin', handleShowLogin);
    };
  }, [ openModal ]);

  const desktopLinkClassName = (menu: string) => {
    return (menuIsOpen && menu === currentMenu)
      ? 'AppHeader__DesktopHeaderLink AppHeader__DesktopHeaderLink--active' 
      : 'AppHeader__DesktopHeaderLink';
  };

  const onlineGenres = appState.taxonomies.onlineGenres;
  const downloadGenres = appState.taxonomies.downloadGenres;

  const [ currentFavGamePage, setCurrentFavGamePage ] = useState<number>(0);
  const [ numFavGamePages, setNumFavGamePages ] = useState<number>(0);
  const [ favoriteGames, setFavoriteGames ] = useState<GameData[]>([]);

  useEffect(() => {
    setFavoriteGames(authState.userFavoriteGames || []);
  }, [ authState.userFavoriteGames ]);

  const fetchFavoriteGames = async (page: number) => {
    try {
      const { games, numPages } = await UsersModel.getFavoriteGamesForUserPaged(
        authState.auth.userId, 
        16, 
        page * 16
      );

      setFavoriteGames(games);
      setNumFavGamePages(numPages);
    } catch (err) {
      logErrorEvent('Error Fetching Initial Favorite Games for User', false, err);
    }
  };

  const openFavoritesModal = async () => {
    await fetchFavoriteGames(0);
    openModal('favoriteGames');
  };

  const paginateFavoriteGames = async (page: number) => {
    setCurrentFavGamePage(page);
    await fetchFavoriteGames(page);
  };

  return (
    <div className={className}>
      <Modal name='mobileLogin'>
        <ModalCard>
          <AuthForms
            formVisible={mobileFormVisible}
            setFormVisible={setMobileFormVisible}
          />
        </ModalCard>
      </Modal>

      <Modal name='favoriteGames'>
        <ModalCard>
          <Slot name='title'>
            Favorite Games
          </Slot>
          <Block>
            {
              If(!authState.ready || !favoriteGames || !favoriteGames.length, () => (
                <p className='--padMd0 --textCenter'>There's nothing here...</p>
              ))
                .Else(() => (
                  <>
                    <Flex 
                      wrap 
                      gap2
                      justifyCenter
                    >
                      {
                        For(favoriteGames, (game) => (
                          <Flex justifyCenter key={game.uid}>
                            <Flex>
                              <SwGameTile
                                {...game}
                              />
                            </Flex>
                          </Flex>
                        ))
                      }
                    </Flex>
                    <Flex justifyCenter className='Block'>
                      <SwPaginator
                        useFirstLast
                        currentPage={currentFavGamePage} 
                        numPages={numFavGamePages}
                        onPaginate={(_currentPage: number, nextPage: number) => {
                          paginateFavoriteGames(nextPage);
                        }}
                      />
                    </Flex>
                  </>
                )).EndIf()
            }
          </Block>
        </ModalCard>
      </Modal>

      {/* Main Header */}
      <Flex alignCenter className={className}>
        <Container>
          <PerDevice>

            {/* Mobile */}
            <Slot name='mobile'>
              <Flex alignCenter justifyCenter wide>
                <Flex>
                  <Link href='/'>
                    <a className='AppHeader__Logo'>
                      <Image
                        src='/images/shockwave.svg'
                        alt='Shockwave.com'
                        width={98}
                        height={34}
                      />
                    </a>
                  </Link>
                </Flex>
              </Flex>
              <Flex 
                className='AppHeader__MobileNavBtn'
                onClick={() => toggleMobileMenu()} 
                alignCenter 
                justifyCenter 
              >
                <IconFA icon={faBars} />
              </Flex>
            </Slot>

            {/* Desktop */}
            <Slot name='desktop'>
              <div className='Flex Flex--alignCenter Flex--wide AppHeader__DesktopHeader' ref={desktopLinksRef}>

                {/* Logo */}
                <Flex>
                  <Link href='/'>
                    <a className='AppHeader__Logo AppHeader__Logo--desktop'>
                      <Image
                        src='/images/shockwaveSquare.svg'
                        alt='Shockwave.com'
                        width={33}
                        height={43}
                      />
                      <Image
                        src='/images/shockwave.svg'
                        alt='Shockwave.com'
                        width={123}
                        height={43}
                      />
                    </a>
                  </Link>
                </Flex>

                {/* Main Header Links */}
                <Flex className='AppHeader__DesktopHeaderLinkFlex'>
                  <span 
                    onClick={(evt) => toggleDesktopMenu('onlineGames', evt.target as HTMLElement)} 
                    className={desktopLinkClassName('onlineGames')}
                  >
                    <span>Online Games</span>
                    <HeaderLinkCaret />
                  </span>
                </Flex>
                <Flex className='AppHeader__DesktopHeaderLinkFlex'>
                  <span 
                    onClick={(evt) => toggleDesktopMenu('downloadGames', evt.target as HTMLElement)} 
                    className={desktopLinkClassName('downloadGames')}
                  >
                    <span>Download Games</span>
                    <HeaderLinkCaret />
                  </span>
                </Flex>
                {
                  If(authState.ready && authState.user, () => (
                    <Flex className='AppHeader__DesktopHeaderLinkFlex'>
                      <span 
                        onClick={(evt) => toggleDesktopMenu('myShockwave', evt.target as HTMLElement)} 
                        className={desktopLinkClassName('myShockwave')}
                      >
                        <span>Favorites</span>
                        <HeaderLinkCaret />
                      </span>
                    </Flex>
                  )).EndIf()
                }
                <Flex className='AppHeader__DesktopHeaderLinkFlex'>
                  <a 
                    className='AppHeader__DesktopHeaderLink'
                    href='https://shop.addictinggames.com/collections/shockwave' 
                    target='_blank' 
                    rel='noreferrer'
                  >
                    <span>Merch</span>
                  </a>
                </Flex>

                {/* Search & User Menu */}
                <Flex pullRight gap1 container alignCenter>
                  <Flex>
                    <SwSearch onBlur={() => setSearchIsOpen(false)} />
                    <SwSearchButton onClick={() => setSearchIsOpen(true)} />
                  </Flex>
                  <Flex>
                    <UserStatus />
                  </Flex>
                </Flex>
              </div>
            </Slot>

          </PerDevice>
        </Container>
      </Flex>

      {/* Pop-out Menu */}
      <div ref={menuRef} className='AppHeader__Menu'>
        <PerDevice>

          {/* Mobile */}
          <Slot name='mobile'>
            <div>
              <Menu>
                <MenuLabel>
                  <Link href='/'>Shockwave Daily Games</Link>
                </MenuLabel>
                <MenuList>
                  {
                    If(appState.mobileGames.length, () => (
                      <>
                        {
                          For(appState.mobileGames, (game) => (
                            <Link href={mobileHref(game.href)} key={game.uid}>
                              <li className='AppHeader__MobileLink'>
                                <img src={game.mobileIcon} alt={`${game.title} thumbnail`} />
                                <span>{game.title}</span>
                              </li>
                            </Link>
                          ))
                        }
                      </>
                    )).Else(() => (
                      <Loader />
                    )).EndIf()
                  }
                </MenuList>

                <MenuLabel>
                  <Link href='/unlimited'>Shockwave Unlimited</Link>
                </MenuLabel>

                <MenuLabel>
                  <Link href='/games-for-pc'>Games for PC</Link>
                </MenuLabel>
              </Menu>

              <br />
              {
                If(authState.ready && authState.user, () => (
                  <MobileUserStatus />
                )).Else(() => (
                  <LoginButton loginOnClick />
                )).EndIf()
              }
            </div>
          </Slot>

          {/* Desktop */}
          <Slot name='desktop'>
            <Container>
              <div className='Flex Flex--justifyCenter' ref={desktopMenuRef}>
                {
                  If(currentMenu === 'onlineGames', () => (
                    // Online Games desktop menu
                    <GenreIconButtons 
                      buttonKey='online'
                      genres={onlineGenres}
                      excludedGenreIds={[ '4', '11', '24' ]}
                    >
                      <Slot name='before'>
                        <GenreIconButton genre={{
                          name: 'All Games',
                          icon: 'all',
                          href: '/online/all-games',
                        }} />
                      </Slot>
                      <Slot name='after'>
                        <GenreIconButton genre={{
                          name: 'EMPIRE',
                          icon: 'empire',
                          href: '/empire',
                        }} />
                        <ButtonLink
                          secondary
                          href='/online/all-genres'
                        >
                          More Genres...
                        </ButtonLink>
                      </Slot>
                    </GenreIconButtons>
                  ))
                    .ElseIf(currentMenu === 'downloadGames', () => (
                      // Download Games desktop menu
                      <GenreIconButtons
                        buttonKey='download'
                        genres={downloadGenres}
                        excludedGenreIds={[ '4', '9', '10', '11', '24' ]}
                      >
                        <Slot name='before'>
                          <GenreIconButton genre={{
                            name: 'All Games',
                            icon: 'all',
                            href: '/download/all-games',
                          }} />
                        </Slot>
                        <Slot name='after'>
                          <ButtonLink
                            secondary
                            href='/download/all-genres'
                          >
                            More Genres...
                          </ButtonLink>
                        </Slot>
                      </GenreIconButtons>
                    ))
                    .ElseIf(currentMenu === 'myShockwave', () => (
                      // My Shockwave desktop menu
                      <Flex directionColumn wide>
                        <Flex directionColumn container gap1 justifySpaceAround>
                          <Flex directionColumn>
                            <Flex alignCenter>
                              <Flex alignCenter>
                                <IconFA icon={faHeart} className='--marSm2__r' />
                                <strong>Favorite Games</strong>
                              </Flex>
                              <Flex className='--padSm2__l'>
                                <Button
                                  small
                                  secondary
                                  onClick={openFavoritesModal}
                                >
                                  View All
                                </Button>
                              </Flex>
                            </Flex>
                            <div className='--padSm3__y'>
                              <Flex gap1 container wrap className='AppHeader__GameTileImgContainer'>
                                {
                                  If(authState.ready && authState.userFavoriteGames?.length, () => (<>
                                    {
                                      For(authState.userFavoriteGames.slice(0, 16), (game) => (
                                        <Link href={game.href} key={game.uid}>
                                          <GameTileImg
                                            src={game.thumbnailImg} 
                                            title={game.title}
                                            small
                                          />
                                        </Link>
                                      ))
                                    }
                                  </>))
                                    .Else(() => (
                                      <p className='AppHeader__NoGames'>
                                        You haven't favorited any games yet.<br />
                                        <RouterLink href='/online/all-games'>Start Playing</RouterLink>
                                      </p>
                                    ))
                                    .EndIf()
                                }
                              </Flex>
                            </div>
                          </Flex>

                          <Flex directionColumn>
                            <Flex alignCenter>
                              <Flex alignCenter>
                                <IconFA icon={faClock} className='--marSm2__r' />
                                <strong>Recently Viewed Games</strong>
                              </Flex>
                              <Flex className='--padSm2__l'>
                                <ButtonLink
                                  small
                                  secondary
                                  href={`/member/profiles/${authState.user?.displayName}?tab=recentActivity`}
                                >
                                  View All
                                </ButtonLink>
                              </Flex>
                            </Flex>
                            <div className='--padSm3__y'>
                              <Flex gap1 container className='AppHeader__GameTileImgContainer'>
                                {
                                  If(authState.ready && authState.user?.uid && recentlyPlayed.user[ authState.user.uid ]?.length, () => (<>
                                    {
                                      For(recentlyPlayed.user[ authState.user.uid ].slice(0, 8), (game) => (
                                        <Link href={game.href} key={game.uid}>
                                          <div>
                                            <GameTileImg
                                              src={game.thumbnailImg} 
                                              title={game.title}
                                              small
                                            />
                                          </div>
                                        </Link>
                                      ))
                                    }
                                  </>))
                                    .Else(() => (
                                      <p className='AppHeader__NoGames'>
                                        You haven't played any games yet.<br />
                                        <RouterLink href='/online/all-games'>Start Playing</RouterLink>
                                      </p>
                                    ))
                                    .EndIf()
                                }
                              </Flex>
                            </div>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))
                    .EndIf()
                }
              </div>
            </Container>
          </Slot>

        </PerDevice>
      </div>

      {/* Mobile menu background overlay */}
      <div className='AppHeader__MobileOverlay' />
    </div>
  );
});
