import { focusInput } from '@/lib/util/focusInput';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Button, Control, createComponent, EventListener, Field, IconFA, IntrinsicProps } from 'react-commons';

export const SEARCH_QUERY_KEY = 'search_api_views_fulltext';

export const swSearchEvents = new EventListener<{
  focus: () => void
}>();

interface SwSearchProps extends IntrinsicProps {
  onBlur?: () => void
}

export default createComponent<SwSearchProps>('SwSearch', {}, function SwSearch ({ className }, props) {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [ query, setQuery ] = useState(router.query[ SEARCH_QUERY_KEY ] as string || '');

  // Perform a search
  const doSearch = () => {
    if (query.length < 3) return;

    const formatted = query.replace(/ /g, '+');
    router.push(`/site-search?${SEARCH_QUERY_KEY}=${formatted}`);
  };

  useEffect(() => {
    // Set the query when on the search page
    const handler = () => {
      if (router.pathname === '/site-search') {
        const search = router.query[ SEARCH_QUERY_KEY ] as string || '';
        setQuery(search);
      } else {
        setQuery('');
      }
    };
    router.events.on('routeChangeComplete', handler);

    // Focus the input on focus event
    const focusHandler = () => {
      if (!inputRef.current) return;
      focusInput(inputRef.current);
    };
    swSearchEvents.on('focus', focusHandler);

    return () => {
      router.events.off('routeChangeComplete', handler);
      swSearchEvents.off('focus', focusHandler);
    };
  });

  return (
    <Field className={className}>
      <Control iconsRight>
        <input
          ref={inputRef}
          type='text'
          className={'Input Input--medium'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && doSearch()}
          onBlur={() => {
            if (props.onBlur) props.onBlur();
            // Only do search on blur if on tablet breakpoint
            if (window.innerWidth < process.env.APP_CONFIG.BREAKPOINTS.desktop) doSearch();
          }}
          placeholder='Search Games'
          autoFocus={router.pathname === '/site-search'}
        />
        <IconFA 
          icon={faSearch}
          onClick={() => doSearch()}
        />
      </Control>
    </Field>
  );
});

export const SwSearchButton = createComponent('SwSearch', {}, function SwSearchButton ({ className }, props) {
  return (
    <Field className={className}>
      <Button
        onClick={(evt) => props.onClick && props.onClick(evt)}
        medium
      >
        <IconFA icon={faSearch} />
      </Button>
    </Field>
  );
});
