import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { If, IntrinsicProps, createComponent } from 'react-commons';

interface PerDeviceProps extends IntrinsicProps {
  redirectOnMobile?: boolean
}

export default createComponent<PerDeviceProps>('PerDevice', {}, function PerDevice ({ slots }, props) {
  const router = useRouter();

  const isMobile = () => {
    return (
      !!window.matchMedia('(max-device-width: 750px)').matches
    );
  };

  useEffect(() => {
    if (props.redirectOnMobile && isMobile()) {
      router.push('/');
    }
  }, [ router, props.redirectOnMobile ]);

  return (
    <>
      {
        If(slots.mobile, () => (
          <div className='--hideOnDesktopDevices'>
            {slots.mobile}
          </div>
        )).EndIf()
      }
      <div className='--hideOnMobileDevices'>
        {slots.desktop}
      </div>
    </>
  );
});
